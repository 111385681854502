/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Webkit scrollbar styling */
::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background: #27374D; /* Track color */
  border-radius: 10px; /* Optional: rounded corners */
}

::-webkit-scrollbar-thumb {
  background: #27374D ; /* Thumb color */
  border-radius: 10px; /* Optional: rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #27374D; /* Thumb color on hover */
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin; /* Options: auto, thin, or none */
  scrollbar-color: #526D82 #f1f1f1; /* Thumb and track color */
}

/* Other styles for your application */
.contact-form {
  padding: 20px;
  /* Add more styles as needed */
}

header{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9;
}
.navbar .navbar-brand{
  font-size: 20px;
  font-weight: bold;
}
.navbar .navbar-brand span{
  color: #526D82;
}
.navbar .navbar-brand img{
  width:220px;
}
.navbar .nav-item{
  margin: auto 20px;
}
.navbar .nav-item .nav-link{
  font-weight: 500;
  color: #526D82;
}

.bdc-design{
  background-image: url('../public/images/breadcrumb-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  padding: 120px 0px 50px 0px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 0.375rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #fff !important;
  content: "/";
}

.breadcrumb-item a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: #DDE6ED !important;
  font-weight: bold;
  font-size: 18px;
}

.hero{
  padding: 100px 0px;
  margin-top: 20px;
}
.hero .spans {
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.hero h1{
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 55px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}

.hero h2{
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 35px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}
  .hero h1 span {
    color: #27374D;
    font-weight: 700;
    text-transform: uppercase;
}
.hero p{
  font-size: 15px;
  text-align: justify;
  margin: 15px 0px;
  font-family: sans-serif;
  color: #757589;
}
.hero button{
  background-color: #27374D;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.5s;
}
.hero button:hover{
  background-color: #fdf8f2;
  color: #27374D;
  border: 1px solid #27374D;
}
.services{
  padding: 100px 0px;
}
.view-more-btn{
  text-align: center;
  margin: 0px 0px 100px 0px;
  background-color: #27374D;
  padding: 10px;
  border: 1px solid #fff;
  transition: 0.5s;
  border-radius: 5px;
}
.read-more{ 
  width: 150px; 
  margin: 20px 0px 0px 0px;
  transition: 0.5s;
  border-radius: 5px;
}
.read-more a{
  color: #526D82;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: 0.5s;
}
.view-more-btn a{
  color: #DDE6ED;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: 0.5s;
}
.view-more-btn:hover{
  background-color: #fff;
  border: 1px solid #27374D;
}
.view-more-btn:hover a{
  color: #27374D;

}
.services .servicesHeading span{
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.services .servicesHeading{
  margin-bottom: 50px;
}
.services .servicesHeading h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}
.servicesBox{
  padding: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transform: scale(10px);
  margin: 15px 0px;
  border-radius: 10px;
  transition: 0.5s;
}
.servicesBox:hover{
  box-shadow: 0px 0px 0px #fff;
}
.services .servicesBox h3{
  color: #526D82;
  font-size: 23px;
  font-weight: bold;
}
.services .servicesBox p{
  font-size: 15px;
  color: #757589;
}
.services .servicesBox button{
  color: #27374D;
  font-weight: bold;
  border: 0px;
  transition: 0.5s;
  padding: 5px 10px;
}
.services .servicesBox img{
  margin-bottom: 20px;
  width: 100%;
}
.services .servicesBox svg{
  color: green;
}
.aboutus{
  padding: 0px 0px 100px 0px;
}
.aboutus .spans {
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.aboutus h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}
.aboutus p{
  font-size: 15px;
  text-align: justify;
  margin: 15px 0px;
  font-family: sans-serif;
  color: #757589;
}
.aboutus button{
  background-color: #27374D;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.5s;
}
.aboutus button:hover{
  background-color: #fdf8f2;
  color: #27374D;
  border: 1px solid #27374D;
}
.testimonila{
  padding: 50px 0px 100px 0px;
  background-color: #dde6ed63;

}
.testimonilaHeading{
  text-align: center;
}
.testimonila .testimonilaHeading .spans {
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.testimonila .testimonilaHeading h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}
.testimonila .testimonilaBox{
  margin: 50px 0px 20px 0px;
  transition: 0.5s;
  padding: 30px;
}
.testimonilaBox:hover{box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.testimonila .testimonilaBox h4{
  font-size: 22px;
  color: #526D82;
  font-weight: bold;
}
.testimonila .testimonilaBox h4 small{ 
  color: rgb(161, 160, 160);
  font-size: 16px;
}
.testimonila .testimonilaBox span{
  font-size: 100px;
  color: #27374D56;
}
.testimonila .testimonilaBox p{
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #757589;
}
.testimonila .testimonilaBox small{
  color: #ff9000;
  font-size: 20px;
}
.inquiry{
  padding: 100px 0px 100px 0px;
  background-image: url('../public/images/inquiry2.jpg');
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* background-position: top; */

}

.inquiry .inquiryHeading{
  text-align: center;
  padding: 50px 0px;
}
.inquiry .inquiryHeading .spans {
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.inquiry .inquiryHeading h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 20px 0px 30px 0px;
  text-transform: capitalize;
  color: #526D82;
}

.inquiry button{
  background-color: #27374D;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.5s;
  padding: 10px 15px;
}
.inquiry button:hover{
  background-color: #fdf8f2;
  color: #27374D;
  border: 1px solid #27374D;
}
.inquiry .inquiryHeading p{
  margin: 20px 0px;
  font-size: 20px;
}
.inquiry .inquiryHeading p span{
    color: green;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
}
.contact-us{
  padding: 100px 0px 0px 0px;
}
.contact-us h1{
  font-size: 45px;
  color: #526D82;
  font-weight: bold;
  margin-bottom: 35px;
}
.contact-us .contact-form{
  padding: 50px;
}
.contact-us .contact-form  input{
  border: 0px;
  border-bottom: 2px solid #d1d1d1;
  border-radius: 0px;
  color: #757589;
  padding: 10px 0px;
  margin: 25px 0px;
}.contact-us .contact-form textarea {
  border: 0px;
  border-bottom: 2px solid #d1d1d1;
  border-radius: 0px;
  color: #757589;
  padding: 10px 0px;
  margin: 25px 0px;
  width: 100%; /* Optional: makes the textarea full-width */
  resize: none; /* Optional: prevents resizing */
  outline: none;
}
.contact-us .contact-form input:focus,
.contact-us .contact-form textarea:focus {  
  outline: none;          /* Remove the default focus outline */
  box-shadow: none;       /* Remove any box shadow */
  border-color: #d1d1d1;  /* Optional: keep the border color consistent */
}

.contact-us .contact-form button{
  background-color: #27374D;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.5s;
  padding: 10px 20px;
  border-radius: 5px;
}
.form-group {
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
}

.form-control {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #757589;
  background: none;
  color: #757589;
  font-size: 16px;
  outline: none;
}

.form-control:focus {
  border-bottom: 2px solid #757589;
}

.form-label {
  position: absolute;
  top: 10px;
  left: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  color: #757589;
  font-size: 16px;
}

.form-label.active {
  top: -20px;
  font-size: 12px;
  color: #000;
}.contact-us .contact-list {
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) -13px 15px 24px -12px;
  padding: 10px 20px;
  width: 450px;
}
.contact-us .contact-list span{
  font-size: 30px;
  color: #526D82;
}
.contact-us .contact-list h2{
  font-size: 25px;
  color: #526D82;
}
.contact-us .contact-list p{
  font-size: 18px;
  color: #757589;
}
.contact-us .contact-list small{
  font-size: 18px;
  color: #27374D;
  font-weight: bold;
}
.footer{
  padding: 100px 0px 50px 0px;
}
.footer-left{
  border-right: 1px solid #757589;
  padding-right: 50px;
}
.footer-left p{
  font-size: 18px;
  font-family: sans-serif;
  color: #757589;
}
.footer-left h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 20px 0px 30px 0px;
  text-transform: capitalize;
  color: #526D82;
}

.footer-left button{
  background-color: #27374D;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.5s;
  padding: 5px 15px;
}
.footer-left button:hover{
  background-color: #fdf8f2;
  color: #27374D;
  border: 1px solid #27374D;
}
.footer .footer-left input{
  border: 0px;
  border-bottom: 2px solid;
  border-radius: 0px;
  color: #757589;
  width:250px;
  padding: 10px 0px;
}
.footer .imp-links h5{
  font-size: 22px;
  font-weight: bold;
  color: #526D82;
  margin: 20px 0px 30px 0px;
}
.footer .imp-links ul {
  list-style: none;
  padding-left: 0px;
}
.footer .imp-links ul li{
  margin: 10px 0px;
}
.footer .imp-links ul li a{
  color: #757589;
  text-decoration: none;
  font-size: 18px;
}
.footer .copy span{
  color: #757589;
  font-size: 18px;
}
.social-links{
  padding: 0px 0px 100px 0px;
  text-align: center;
}
.social-links span{
  font-size: 50px;
  padding: 10px 30px;
  color:#757589;
}
.not-found {
  text-align: center;
  padding: 100px;
}

.not-found h1 {
  font-size: 100px;
  margin: 0;
}

.not-found p {
  font-size: 24px;
}

.not-found a {
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
}
.cbtn{
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 50px 10px 0px 0px;
}
.cbtn span{
  background-color: #526D82;
  padding: 0px 1px;
  font-size: 20px;
  line-height: 15px;
  color: #d1d1d1;
  margin: 10px;
  cursor: pointer;
}
.cimg img{
  width: 200px;
  height: 120px;
  transition: 0.5s;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.cimgp{
  padding: 15px 0px 50px 0px;
  display:flex;
  justify-content:space-between;
  align-items: center;
  transition: 0.5s;
  gap: 70px;
  overflow: hidden;
}
.not-found a:hover {
  text-decoration: underline;
}

.blog{
  padding: 100px 0px 0px 0px;
}
.BlogHeading span{
  background-color: #27374D1f;
  padding: 5px 15px;
  color: #27374D;
  font-weight: bold;
}
.BlogHeading h2{
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 10px 0px 20px 0px;
  text-transform: capitalize;
  color: #526D82;
}
.blog-data {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 50px 0px;
  padding: 20px;
  text-align: left;
}

.blog-data img {
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  height: 170px;
}

.blog-data h3 {
  font-size: 1.5em;
  margin: 15px 0 10px;
  color: #27374D;
}

.blog-data p {
  color: #757589;
  font-size: 1em;
  margin: 5px 0;
}
/* Blog.css */

.blog {
  padding: 50px 0px 25px 0px;
}

.blog-heading {
  margin-bottom: 20px;
}

.blog-data {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.blog-data img {
  max-width: 100%;
  border-radius: 5px;
}

.blog-data h3 {
  font-size: 1.5em;
  margin: 15px 0 10px;
}

.blog-data p {
  color: #666;
  margin: 5px 0;
}
/* BlogDetails.css */

.blog-details {
  padding: 20px;
}

.blog-details img {
  max-width: 100%;
  border-radius: 5px;
}

.blog-details h2 {
  font-size: 2em;
  margin: 20px 0 10px;
}

.blog-details p {
  color: #666;
  margin: 5px 0;
}
.floating-chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25D366;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.floating-chat-button img {
  width: 30px;
  height: 30px;
}

.floating-chat-button:hover {
  background-color: #128C7E;
  transition: background-color 0.3s ease;
}
/* ChatBot.css */
.chat-bot {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  border-bottom: 1px solid #ddd;
}

.message {
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
}

.message.user {
  background-color: #d1e7dd;
  text-align: right;
}

.message.bot {
  background-color: #f8d7da;
  text-align: left;
}

.chat-input-form {
  display: flex;
}

.chat-input-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input-form button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input-form button:hover {
  background-color: #0056b3;
}

@media(min-width:360px) and (max-width:768px){
  .contact-us .contact-list {
    width: 100%;
}
.hero {
  margin-top: 75px;
}
}